import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d897158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search_box" }
const _hoisted_2 = { class: "search" }

import { reactive,ref, watch } from "vue";
import { $apiUserPhoneSearch } from '@/api/modules/chat';
import { ElMessage } from 'element-plus'

export default {
  props: {
  clearSearch:Boolean,
  serviceList:Array,
},
  emits: ['isSearch','getSearchInfo'],
  setup(__props, { emit: emits }) {

const props = __props

const searchVal = ref(null)
const searchRef = ref(null)



watch(()=>(props.clearSearch),()=>{
  if(!props.clearSearch) searchVal.value = null
})
// 验证手机号格式
const testPhone = (phone) => {
  return (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(phone)
}
// 搜索
const searchHandler = async()=>{
  if(!testPhone(searchVal.value)) return ElMessage.warning('请输入正确的手机号格式~')
  const {data} = await $apiUserPhoneSearch({phone:searchVal.value})
  data.u_avatar = data.avatar
  data.u_id = data.id
  data.u_name = data.name
  emits('getSearchInfo',[data])
}

// 搜索已聊天的用户
const filterService = ()=>{
  const list = props.serviceList.filter(item=>(item.u_name.includes(searchVal.value)||(item.u_phone&&item.u_phone.search(searchVal.value)))) 
  emits('getSearchInfo',list)
}

watch(()=>searchVal.value,(val)=>{
  emits('isSearch',!!val);
  filterService()
})

return (_ctx, _cache) => {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", {
        class: "iconfont search_icon",
        onClick: searchHandler
      }, ""),
      _createVNode(_component_el_input, {
        modelValue: searchVal.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((searchVal).value = $event)),
        placeholder: "用户账号/手机号",
        clearable: "",
        ref_key: "searchRef",
        ref: searchRef,
        onKeydown: _withKeys(searchHandler, ["enter"])
      }, null, 8, ["modelValue", "onKeydown"])
    ])
  ]))
}
}

}