const getAttr = (obj, key) =>
  obj.currentStyle
    ? obj.currentStyle[key]
    : window.getComputedStyle(obj, false)[key];
const vDrag = {
  inserted(el) {
    const target = el;
    const header = el.querySelector(".chat-head");
    header.style.cursor = "move";
    header.onmousedown = (e) => {
      /* 记录按下时鼠标的坐标和目标元素的 left、top 值 */ const currentX =
        e.clientX;
      const currentY = e.clientY;
      const left = parseInt(getAttr(target, "left"));
      const top = parseInt(getAttr(target, "top"));
      const minLeft =
        target.offsetLeft + parseInt(getAttr(target, "width")) - 50;
      const maxLeft =
        parseInt(getAttr(document.body, "width")) - target.offsetLeft - 50;
      const minTop = target.offsetTop;
      const maxTop =
        parseInt(getAttr(document.body, "height")) -
        target.offsetTop -
        parseInt(getAttr(header, "height"));
      document.onmousemove = (event) => {
        /* 鼠标移动时计算每次移动的距离，并改变拖拽元素的定位 */ const disX =
          event.clientX - currentX;
        const disY = event.clientY - currentY;
        /* 判断左、右边界 */ if (disX < 0 && disX <= -minLeft) {
          target.style.left = `${left - minLeft}px`;
        } else if (disX > 0 && disX >= maxLeft) {
          target.style.left = `${left + maxLeft}px`;
        } else {
          target.style.left = `${left + disX}px`;
        }
        /* 判断上、下边界 */ if (disY < 0 && disY <= -minTop) {
          target.style.top = `${top - minTop}px`;
        } else if (disY > 0 && disY >= maxTop) {
          target.style.top = `${top + maxTop}px`;
        } else {
          target.style.top = `${top + disY}px`;
        } /* 阻止事件的默认行为，可以解决选中文本的时候拖不动 */
        return false;
      };
      /* 鼠标松开时，拖拽结束 */ document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
  /*复位 */ update(el) {
    const target = el.children[0];
    target.style.left = "";
    target.style.top = "";
  },
  /* 最后卸载时，清除事件绑定 */ unbind(el) {
    const header = el.children[0].children[0];
    header.onmousedown = null;
  },
};
export default vDrag;
function float(el) {
  var box = el;
  var maxLeft = document.documentElement.clientWidth - box.offsetWidth;
  var maxtop = document.documentElement.clientHeight - box.offsetHeight;
  /* Math.random()*100 */ /* y变量 */ var y = 100;
  /* x变量 */ var x = 100;
  function piaofu() {
    /* 最初的最左距离 */ var oldleft = box.offsetLeft;
    /* 新的左距离 */ var newleft = oldleft + x;
    /* 最初的高度距离 */ var oldtop = box.offsetTop;
    /* 新的高度距离 */ var newtop = oldtop + y;
    if (newtop > maxtop) {
      newtop = maxtop;
    }
    if (newleft > maxLeft) {
      newleft = maxLeft;
    }
    if (newtop < 0) {
      newtop = 0;
    }
    if (newleft < 0) {
      newleft = 0;
    }
    box.style.left = newleft + "px";
    box.style.top = newtop + "px";
    if (newtop == 0 || newtop == maxtop) {
      y = -1 * y;
    }
    if (newleft == 0 || newleft == maxLeft) {
      x = -1 * x;
    }
  }
  var timer = setInterval(piaofu, 5);
  box.addEventListener("mouseover", function () {
    clearInterval(timer);
  });
  box.addEventListener("mouseout", function () {
    timer = setInterval(piaofu, 5);
  });
}
/* 根尺寸 */ function resetSize() {
  var winWidth =
    document.documentElement.offsetWidth || document.body.offsetWidth;
  winWidth = winWidth < 1366 ? 1366 : winWidth;
  var oHtml = document.getElementsByTagName("html")[0];
  oHtml.style.fontSize = (100 * winWidth) / 1920 + "px";
  window.addEventListener("resize", function () {
    var winWidth =
      document.documentElement.offsetWidth || document.body.offsetWidth;
    winWidth = winWidth < 1400 ? 1400 : winWidth;
    var oHtml = document.getElementsByTagName("html")[0];
    oHtml.style.fontSize = (100 * winWidth) / 1920 + "px";
  });
}
